<template>
  <div class="relative">
    <button @click="$root.toggleNotifications"
            class="py-4 px-1 relative border-2 border-transparent text-gray-400 rounded-full hover:text-gray-500 focus:outline-none transition duration-150 ease-in-out"
            aria-label="Cart">
      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
           stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
      </svg>
      <span class="absolute inset-0 object-right-top -mr-6">
          <div
              class="inline-flex items-center px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-christine text-white">
            6
          </div>
      </span>
    </button>
    <div id="notifications"
         class="hidden origin-top-right absolute right-0 mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
         role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
      <div class="pb-4" role="none">
        <span class="text-green-500 block pt-4 px-4 text-medium">Neuer Einsatzort verfügbar</span>
        <span
            class="text-gray-700 block pt-2 px-4 text-sm">Leipzig, Brühl ist ab sofort verfügbar</span>
      </div>
      <div class="pb-4" role="none">
        <span class="text-green-500 block pt-4 px-4 text-medium">Neuer Einsatzort verfügbar</span>
        <span
            class="text-gray-700 block pt-2 px-4 text-sm">Leipzig, Brühl ist ab sofort verfügbar</span>
      </div>
      <div class="pb-4" role="none">
        <span class="text-green-500 block pt-4 px-4 text-medium">Neuer Einsatzort verfügbar</span>
        <span
            class="text-gray-700 block pt-2 px-4 text-sm">Leipzig, Brühl ist ab sofort verfügbar</span>
      </div>
      <div class="pb-4" role="none">
        <span class="text-green-500 block pt-4 px-4 text-medium">Neuer Einsatzort verfügbar</span>
        <span
            class="text-gray-700 block pt-2 px-4 text-sm">Leipzig, Brühl ist ab sofort verfügbar</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserNotifications',
};
</script>