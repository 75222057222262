<template>
  <div class="bg-white shadow rounded p-5">
    <div class="flex flex-row justify-between items-center">
      <div class="p-5 text-2xl">
        <span class="font-bold">{{ monthNames[(month - 1)] }}</span> {{ year }}
      </div>
      <div class="p-5 text-2xl flex flex-col">
        <div>
          <a @click="setThisMonth" class="cursor-pointer"
             v-if="(this.month !== (new Date()).getMonth())">Heute</a>
        </div>
        <div class="xs:flex justify-end space-x-3">
          <a href="javascript:void(0)" @click="decrementMonth" class="cursor-pointer hover:text-christine"
             v-if="showPreviousMonthButton">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>
          <a href="javascript:void(0)" @click="incrementMonth" class="cursor-pointer hover:text-christine">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap divide-x divide-gray-200">
      <week-day
          class="hidden xs:hidden lg:inline"
          :day-name="day"
          v-for="(day, index) in dayNames"
          :key="'wLong' + index"
          :additional-class="{'border-r': true, 'border-l': index === 0}"
      ></week-day>

      <week-day
          class="hidden lg:hidden xs:inline"
          :day-name="day"
          v-for="(day, index) in shortDayNames"
          :key="'wShort' + index"
          :additional-class="{'border-r': true, 'border-l': index === 0}"
      ></week-day>

      <day
          :day-number="blankDay"
          :holiday="false"
          :class="{'text-gray-400 cursor-default': true, 'border-b': true}"
          v-for="(blankDay, blankIndex) in blankDays"
          :key="'bd' + blankIndex"
      ></day>

      <day
          :day-number="date.number"
          v-for="(date, dateIndex) in days"
          :key="'d' + dateIndex"
          :holiday="date.holiday"
          :holiday-name="date.holidayName"
          :past="date.past"
          @toggleModal="toggleModal"
          :is-available="isAvailable((date.number + 1))"
          :class="{'border-b': true, 'bg-orange-50': date.holiday === true, 'bg-green-50 cursor-pointer': isToday(date.number) === true, 'cursor-pointer': date.past === false, 'bg-gray-50': date.past === true, 'bg-red-50': date.weekend}"
      ></day>
    </div>
    <availability-modal v-if="availability.show"
                        :is-availability="availability.show"
                        :date="availability.date"
                        :is-toggled="availability.isToggled"
                        @toggleModal="toggleModal"
                        @saveModal="saveAvailability"
    ></availability-modal>
  </div>
</template>

<script>
import WeekDay from './weekDay';
import Day from './day';
import AvailabilityModal from './AvailabilityModal';

export default {
  name      : 'Calendar',
  components: {
    AvailabilityModal,
    Day,
    WeekDay,
  },
  data() {
    return {
      month                  : null,
      year                   : null,
      day                    : null,
      days                   : [],
      blankDays              : [],
      monthNames             : ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      dayNames               : ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
      shortDayNames          : ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
      loaded                 : true,
      dayOfWeek              : 1,
      availability           : {
        show     : false,
        date     : null,
        isToggled: false,
      },
      showPreviousMonthButton: false,
      availabilities         : [],
    };
  },
  mounted() {
    this.loadCalendar();
  },
  updated() {
    const today = new Date();

    if (today.getFullYear() >= this.year) {
      this.showPreviousMonthButton = today.getMonth() < (this.month - 1);
    }
  },
  methods: {
    isAvailable(dayNumber) {
      const currentDate      = new Date(this.year, (this.month - 1), dayNumber).toISOString().split('T')[0];
      let availabilityObject = this.availabilities.find((availability) => {
        if (availability.date === currentDate) {
          return availability;
        }
      });

      if (availabilityObject !== undefined) {
        return availabilityObject.available;
      } else {
        return undefined;
      }
    },
    setThisMonth() {
      const today = new Date();
      this.year   = today.getFullYear();
      this.month  = today.getMonth() + 1;
      this.day    = today.getDate();

      this.loadCalendar();
    },
    toggleModal(payload) {
      if (payload !== null) {
        const currentDate      = new Date(this.year, (this.month - 1), payload).toISOString().split('T')[0];
        this.availability.date = currentDate;

        const availability = this.availabilities.find(availability => {
          return (availability.date === currentDate && availability.available === true);
        });

        if (availability !== undefined) {
          this.availability.isToggled = availability.available;
        } else {
          this.availability.isToggled = false;
        }
      } else {
        this.availability.date = null;
      }

      this.availability.show = !this.availability.show;
    },
    saveAvailability(payload) {
      axios.post('calendar/availabilities/save', payload)
          .then(function () {
            this.availability.show = false;
            this.loadAvailabilities();
          }.bind(this));
    },
    loadAvailabilities() {
      axios.get('calendar/availabilities')
          .then(function (response) {
            this.availabilities = response.data.data;
          }.bind(this));
    },
    decrementMonth() {
      if (this.month === 1) {
        this.month = 12;
        this.year--;
      } else {
        this.month--;
      }

      this.loadCalendar();
    },
    incrementMonth() {
      if (this.month === 12) {
        this.month = 1;
        this.year++;
      } else {
        this.month++;
      }

      this.loadCalendar();
    },
    async loadCalendar() {
      this.loaded   = false;
      let today     = new Date();
      let uriParams = [];
      let uri       = 'calendar';

      if (this.month !== null && this.month > 0) {
        uriParams.push('month=' + this.month);
      }

      if (this.year !== null && this.year > today.getFullYear()) {
        uriParams.push('year=' + this.year);
      }

      if (uriParams.length > 0) {
        uri += '?' + uriParams.join('&');
      }

      axios.get(uri)
          .then(function (response) {
            this.year      = response.data.year;
            this.month     = response.data.month;
            this.day       = response.data.day;
            this.blankDays = response.data.blankDays;
            this.days      = response.data.days;
            this.loadAvailabilities();
          }.bind(this))
          .finally(function () {
            this.loaded = true;
          }.bind(this));
    },
    isToday(date) {
      const today = new Date();
      const d     = new Date(this.year, (this.month - 1), date);

      return today.toDateString() === d.toDateString();
    },
  },
};
</script>
