<template>
  <div class="px-2 w-1/7 min-w-1/7 lg:h-32 xs:h-10 cursor-default text-sm" :class="additionalClass"
       @click="toggleModal">
        <span :class="{'font-bold text-green-600': isAvailable, 'font-bold text-red-600': isAvailable === false}">
            {{ dayNumber }} <span v-if="holiday === true" class="text-xs md:inline hidden">- {{ holidayName }}</span>
        </span>
  </div>
</template>

<script>
export default {
  name   : 'day',
  props  : [
    'dayNumber',
    'additionalClass',
    'holidayName',
    'holiday',
    'past',
    'isAvailable',
  ],
  methods: {
    toggleModal() {
      if (this.past === false) {
        this.$emit('toggleModal', this.dayNumber + 1);
      }
    },
  },
};
</script>
