import Vue from 'vue';

require('./bootstrap');

const userNotifications = Vue.component('user-notifications', require('../globalComponents/modules/UserNotifications').default);
const calendar          = Vue.component('calendar', require('./components/calendar/Calendar').default);
const contractListItem  = Vue.component('list-item', require('../globalComponents/contracts/ListItem').default);

const loginForm     = Vue.component('login-form', require('./../globalComponents/Authentication/LoginForm').default);
const notifications = Vue.component('notifications', require('./../globalComponents/modules/Notifications').default);

const app = new Vue(
    {
        el        : '#my-app',
        components: {
            calendar,
            contractListItem,

            // modules
            userNotifications,
            notifications,

            // authentication
            loginForm,
        },
        methods   : {
            loginToApi() {
                return axios.get('sanctum/csrf-cookie');
            },
            toggleMobileMenu(triggerEvent) {
                let mobileMenu = document.getElementById('mobile-menu');

                if (triggerEvent === 'open') {
                    mobileMenu.classList.remove('hidden');
                } else if (triggerEvent === 'close') {
                    mobileMenu.classList.add('hidden');
                }
            },
            toggleUserMenu() {
                let userMenu = document.getElementById('user-menu');
                userMenu.classList.toggle('hidden');
            },
            toggleNotifications() {
                let notifications = document.getElementById('notifications');
                notifications.classList.toggle('hidden');
            },
            toggleCommissions(providerIdentifier) {
                let commission = document.getElementById('provider-' + providerIdentifier);

                commission.classList.toggle('hidden');

                if (commission.classList.contains('hidden') !== false) {
                    document.getElementById('providerListIcon-' + providerIdentifier).classList.replace('-rotate-180', 'rotate-0');
                } else {
                    document.getElementById('providerListIcon-' + providerIdentifier).classList.replace('rotate-0', '-rotate-180');
                }
            },
            toggleNewsEntry(newsEntryIdentifier) {
                let entry = document.getElementById('newsEntry-' + newsEntryIdentifier);

                entry.classList.toggle('hidden');

                if (entry.classList.contains('hidden') !== false) {
                    document.getElementById('newsEntryArrow-' + newsEntryIdentifier).classList.replace('-rotate-180', 'rotate-0');
                } else {
                    document.getElementById('newsEntryArrow-' + newsEntryIdentifier).classList.replace('rotate-0', '-rotate-180');
                }
            },
        },
    },
);
