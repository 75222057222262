<template>
  <div class="flex flex-col divide-y divide-y-gray-400 bg-white shadow">
    <div class="flex flex-row text-lg p-4 justify-between items-center">
      <span v-html="listTitle"></span>
      <span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clip-rule="evenodd"/>
          </svg>
      </span>
    </div>
    <div class="p-4 text-sm flex flex-row space-x-8">
      <div class="flex flex-col space-y-4" v-for="(listItems, columnIndex) in listColumns" v-if="listColumns.length > 0"
           :key="columnIndex">
        <div class="flex flex-col" v-for="(listItem, itemIndex) in listItems" :key="itemIndex">
          <span class="text-gray-500 font-semibold" v-html="listItem.title"></span>
          <span class="text-gray-900" v-for="(item, contentIndex) in listItem.items" v-html="item"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'ShowListItem',
  props: [
    'listTitle',
    'listColumns',
  ],
};
</script>
