<template>
  <div class="fixed inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
       aria-modal="true"
       v-show="isAvailability">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center xs:block xs:p-0">
      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"
           @click="toggleModal"></div>
      <span class="inline-block xs:align-middle xs:h-screen" aria-hidden="true">&#8203;</span>
      <div
          class="inline-block align-bottom bg-white rounded px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all xs:my-8 xs:align-middle xs:max-w-xs xs:w-full xs:p-6">
        <div>
          <div class="mt-3 text-center xs:mt-5">
            <div class="mt-2 flex justify-center items-center space-x-3">
              <span>Nicht verfügbar</span>
              <button type="button"
                      :class="{'bg-red-200': form.available === false, 'bg-green-200': form.available === true}"
                      class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0"
                      role="switch" aria-checked="false" @click="toggleButton">
                <span class="sr-only">Use setting</span>
                <span :class="{'translate-x-0': form.available === false, 'translate-x-5': form.available === true}"
                      class="pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200">
                  <span
                      :class="{'opacity-100 ease-in duration-200': form.available === false, 'opacity-0 ease-out duration-100': form.available === true}"
                      class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                      aria-hidden="true">
                    <svg class="h-3 w-3 text-red-400" v-if="form.available === false" fill="none"
                         viewBox="0 0 12 12">
                      <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor"
                            stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round"/>
                    </svg>
                  </span>
                  <span
                      :class="{'opacity-0 ease-out duration-100': form.available === false, 'opacity-100 ease-in duration-200': form.available === true}"
                      class="absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                      aria-hidden="true">
                    <svg class="h-3 w-3 text-green-400" v-if="form.available === true"
                         fill="currentColor" viewBox="0 0 12 12">
                      <path
                          d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"/>
                    </svg>
                  </span>
                </span>
              </button>
              <span>Verfügbar</span>
            </div>
          </div>
        </div>
        <div class="mt-5 xs:mt-6">
          <button type="button" @click="saveAvailability"
                  class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-cloud-burst text-base font-medium text-white hover:bg-cloud-burst-400 focus:outline-none focus:ring-0 xs:text-sm">
            Speichern
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name : 'AvailabilityModal',
  props: ['isAvailability', 'date', 'isToggled'],
  data() {
    return {
      form: {
        date     : this.date,
        available: false,
      },
    };
  },
  mounted() {
    this.form.date      = this.date;
    this.form.available = this.isToggled;
  },
  methods: {
    toggleButton() {
      this.form.available = !this.form.available;
    },
    toggleModal() {
      this.$emit('toggleModal', null);
    },
    saveAvailability() {
      this.$emit('saveModal', this.form);
    },
  },
};
</script>
