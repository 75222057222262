window._     = require('lodash');
window.axios = require('axios');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */
let apiProtocol = 'http://';
let host        = 'ffenergie.lab';

if (process.env.MIX_APP_ENV === undefined) {
    apiProtocol = 'https://';
    host        = 'ffenergie.dev';
}

window.axios.defaults.baseURL                            = apiProtocol + 'api.my.' + host + '/';
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type']     = 'application/json';
window.axios.defaults.headers.common['Accept']           = 'application/json';
window.axios.defaults.withCredentials                    = true;
window.axios.defaults.headers.common                     = {
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
};

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster : 'pusher',
    key         : 463676285662703,
    wsHost      : 'websockets.informatjonas.dev',
    wsPort      : 443,
    forceTLS    : true,
    disableStats: false,
});
