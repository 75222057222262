<template>
  <div class="w-1/7 px-2 text-center font-bold h-8 bg-gray-100 cursor-default border-t border-b"
       :class="additionalClass">
    {{ dayName }}
  </div>
</template>

<script>
export default {
  name : 'weekDay',
  props: [
    'dayName',
    'additionalClass',
  ],
};
</script>