<template>
  <div class="space-y-6">
    <div>
      <label for="email" class="block text-sm font-medium text-gray-700">
        E-Mail-Adresse
      </label>
      <div class="mt-1">
        <input id="email" name="email" type="email" autocomplete="email" required v-model="email"
               @keyup.enter="loginUser"
               class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
      </div>
    </div>

    <div>
      <label for="password" class="block text-sm font-medium text-gray-700">
        Passwort
      </label>
      <div class="mt-1">
        <input id="password" name="password" type="password" autocomplete="current-password" required
               v-model="password" @keyup.enter="loginUser"
               class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
      </div>
    </div>

    <div class="flex items-center justify-between">
      <div class="text-sm">
        <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">
          Passwort vergessen?
        </a>
      </div>
    </div>

    <div>
      <button type="button" @click="loginUser"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cloud-burst-300 hover:bg-cloud-burst-500 focus:outline-none focus:ring-0">
        Login
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  mounted() {
    localStorage.clear();
  },
  data() {
    return {
      email   : '',
      password: '',
    };
  },
  methods: {
    loginUser() {
      axios.post('auth/login', {
        email   : this.email,
        password: this.password,
      }).then((response) => {
        if (response.data.success === true) {
          localStorage.setItem('token', response.data.token);
          window.location.href = '/';
        }
      });
    },
  },
};
</script>
